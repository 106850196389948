<template>
  <div class="m-5">
    <hamburger :path="$route.path" />
    <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
        <h1 class="text-lg font-semibold uppercase">User Management</h1>
         <nuxt-link
          :to="`/users/create`" class="flex flex-row gap-2 items-center bg-white text-primary rounded-md px-2 py-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
            </svg>
            <span>Tambah</span>
        </nuxt-link>
    </div>

    <div class="w-full bg-secondary rounded-lg mt-5 p-5 relative">
      <table class="table-auto w-full">
        <thead>
          <tr class="text-left">
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Verify</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-if="users && users.data && users.data.length > 0">
          <tr v-for="(user, index) in users.data" :key="index">
            <td>{{ (index + 1) * users.current_page }}</td>
            <td class="flex flex-row items-center gap-1">
              <span>{{ user.name }} </span>
              <svg v-if="$auth.user.id === user.id" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
            </td>
            <td>{{ user.email }}</td>
            <td>
              <button
                v-if="user.email_verified_at"
                class="rounded-sm p-1 bg-primary text-white"
                disabled
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
              <button
                v-else
                class="rounded-sm p-1 bg-gray-400 text-white"
                disabled
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </td>
            <td class="flex gap-1">
              <nuxt-link
                :to="`/users/${user.id}/edit`"
                class="rounded-sm p-1 bg-primary text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </nuxt-link>

              <button
                class="rounded-sm p-1 text-white"
                :class="
                  user.is_admin
                    ? 'bg-gray-500 cursor-not-allowed'
                    : 'bg-red-500'
                "
                :disabled="user.is_admin"
                @click.prevent="deleteUser(user.id)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">No Data</td>
          </tr>
        </tbody>
      </table>
    </div>
      <div class="sticky -bottom-3 bg-white flex">
          <pagination
            :data="users"
            @nextPage="getUsers"
            @prevPage="getUsers"
          />
      </div>
  </div>
</template>

<script>
import hamburger from '~/components/common/hamburger.vue'
import pagination from '~/components/common/pagination.vue'
import { mutateUsers } from'~/common/helper/user'

export default {
  name: 'UsersPage',
  components: {
    hamburger,
    pagination
  },
  layout: 'UserNavigation',
  middleware: ['admin'],
    transition: "slide-bottom",
  async asyncData({ $axios }) {
      const params = {
        page: 1,
      }
      
      const data = await $axios.$get(`/api/v1/users`, { params })
      return { users: mutateUsers(data.data) }
  },
  methods:{
    getUsers(page = 1) {
        this.$swal.fire('loading users data')
        this.$swal.showLoading()

        const params = {
          page,
        }
        
        this.$axios.$get('/api/v1/users', { params })
        .then((res) => {
          if (res.success) {
            const data = res.data
            this.users = this.mutateUsers(data)
          }
        })
        .finally(() => this.$swal.close())
    },
    deleteUser(id){
      const self = this

    this.$swal
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          self.$swal.fire('Deleting your data...')
          self.$swal.showLoading()
          this.$axios.$delete('/api/v1/users/' + id)
            .then((res) => {
                if (res.success) {
                  self.$swal.hideLoading()
                  self.$swal
                    .fire('Delete!', res.message, 'success')
                    .then((res2) => {
                      if (res2.isConfirmed) {
                        self.getUsers()
                      }
                    })
                } else {
                  self.$swal.fire('Error!', res.message, 'error')
                }
              })
        }
      })
    }
  }
  
}
</script>