<template>
    <div>
      <hamburger :path="$route.path" />
      <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
          <h1 class="text-lg font-semibold uppercase">Wedding List</h1>
          <nuxt-link
            v-if="is_admin"
            :to="`/wedding/create`" class="flex flex-row gap-2 items-center bg-white text-primary rounded-md px-2 py-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
            </svg>
            <span>Tambah</span>
        </nuxt-link>
      </div>
        <table class="table-auto w-full">
        <thead>
          <tr class="text-left">
            <th>#</th>
            <th>Wedding Code</th>
            <th>Publish</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-if="weddings && weddings.data && weddings.data.length > 0">
          <tr v-for="(wed, index) in weddings.data" :key="index">
            <td>{{ (index + 1) * weddings.current_page }}</td>
            <td>{{ wed.wedding_code }}</td>
            <td>
              <button class="p-1 rounded-md text-white" :class="wed.is_published ? 'bg-primary' : 'bg-gray-600'" @click="is_admin ? setPublished(wed) : ''">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                  <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                </svg>
              </button>
            </td>
            <td>
                <nuxt-link
                    :to="`/wedding/${wed.wedding_code}/guest`"
                    >
                    <button 
                    class="px-1 py-1 rounded-md bg-primary text-white"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                    </button>
                </nuxt-link>
                  <nuxt-link
                    :to="`/wedding/${wed.wedding_code}/guest-mobile`"
                    >
                    <button 
                    class="px-1 py-1 rounded-md bg-rose-500 text-white"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                    </svg>
                    </button>
                </nuxt-link>
                <nuxt-link
                    :to="`/wedding/${wed.wedding_code}/wish`"
                    >
                    <button 
                    class="px-1 py-1 rounded-md bg-yellow-400 text-white"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                      <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                    </svg>
                    </button>
                </nuxt-link>
                 <nuxt-link
                    v-if="is_admin"
                    :to="`/wedding/${wed.wedding_code}`"
                    >
                    <button 
                    class="px-1 py-1 rounded-md bg-blue-400 text-white"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                    </button>
                </nuxt-link>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">No Data</td>
          </tr>
        </tbody>
      </table>
       <div class="sticky -bottom-3 bg-white flex">
          <pagination
            :data="weddings"
            @nextPage="getWeddings"
            @prevPage="getWeddings"
          />
      </div>
    </div>
</template>

<script>
import { isAdmin } from '~/common/helper/validator'
import hamburger from '~/components/common/hamburger.vue'
import pagination from '~/components/common/pagination.vue'

export default {
    name: "UserPage",
    components: { hamburger, pagination },
    layout: 'UserNavigation',
    transition: "slide-bottom",
     async asyncData({ $axios }) {
      const params = {
        page: 1,
      }
      
      const data = await $axios.$get(`/api/v1/weddings`, { params })
      return { weddings: data.data }
    },
    computed:{
      user(){
            return this.$auth.user
      },
      is_admin(){
          return isAdmin(this.user)
      }
    },
    methods:{
      setPublished(payload) {
        const self = this
        const isPublish = payload.is_published
        const url = isPublish ? `/api/v1/weddings/${payload.id}/unpublish` : `/api/v1/weddings/${payload.id}/publish`

        self.$swal
        .fire({
            title: 'Are you sure?',
            text: `You will ${isPublish ? 'unpublish' : 'publish'} this wedding invitation`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${isPublish ? 'unpublish' : 'publish'} it!`,
        })
        .then((result) => {
            if (result.isConfirmed) {
            self.$swal.showLoading()
                this.$axios.$patch(url)
                .then((res) => {
                    if (res.success) {
                      this.weddings.data = this.weddings.data.map((wed) => wed.id === payload.id ? res.data : wed) 

                      self.$swal.fire(
                          isPublish ? 'Unpublished' : 'Published',
                          `Your wedding invitation has been ${isPublish ? 'unpublished' : 'published'}.`,
                          'success'
                      )

                    } 
                }).catch(error => {
                    self.$swal.fire('Error!', error.response?.data.message, 'error')
                })
            }
        })
      },
      getWeddings(page = 1) {
          const self = this
          self.$swal.fire('fetching new data wedding..')
          self.$swal.showLoading()
          
          const params = {
            per_page: 10,
            page,
          }

          this.$axios.$get(`/api/v1/weddings`, { params })
          .then(res => {
            if(res.success){
              this.weddings = res.data
            }
            self.$swal.close()
          })
      },
    }
}
</script>