<template>
   <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 h-screen">
    <div class="hidden md:col-span-3 lg:col-span-4 bg-tertiary md:grid place-items-center" v-lazy-background lazy-background="~/static/themes/theme3/texture.png">
      <div class="relative w-full lg:w-3/5 flex justify-center">
            <nuxt-img preload provider="static" format="webp" src="/images/boy-girl.png" loading="lazy"/>
            <nuxt-img preload provider="static" format="webp" src="/images/phone.png" loading="lazy" class="absolute top-0 -right-32 sm:right-1/4 h-32 w-auto bounce-1"/>
            <nuxt-img preload provider="static" format="webp" src="/images/schedule.png" loading="lazy" class="absolute top-0 -left-32 sm:left-1/4 h-32 w-auto bounce-2"/>
      </div>
    </div>
    <div class="grid place-items-center" v-lazy-background lazy-background="~/static/themes/theme3/texture.png">
      <form autocomplete="off" class="w-4/5 md:w-full px-5">
        <div class="form-group flex flex-col">
          <label for="email">Email</label>
          <input
            v-model="email"
            name="email"
            type="email"
            placeholder="Input your email here.."
            autocomplete="off"
            class="ring-primary peer invalid:ring-red-500 ring-2 outline-none"
          />
           <p class="invisible peer-invalid:visible text-red-500 text-sm">
            Please provide a valid email address.
          </p>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            v-model="password"
            name="password"
            type="password"
            placeholder="Input your password here.."
            class="outline-none"
          />
        </div>
        <button
          class="bg-primary py-2 px-2 rounded-sm text-white w-full flex justify-center group mt-4"
          @click.prevent="login"
        >
          <div v-if="!loading" class="flex flex-row items-center">
          <span>LOGIN</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 group-hover:translate-x-2 duration-200 ease-linear" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
          </svg>
          </div>
          <span v-else class="animate-spin">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  transition: "slide-bottom",
  data() {
    return {
      email : '',
      password : '',
      loading : false,
    }
  },
  methods:{
    async  login(){
      try {
        this.$swal.fire('Validating your data ygy ...')
        this.$swal.showLoading()
         this.loading = true
          await this.$auth.loginWith('local', {
            data: {
              email: this.email,
              password: this.password,
            },
      })
      this.$swal.close()
      } catch (error) {
        this.loading = false
        this.$swal.fire('Failed!', error?.response?.data?.message ?? '', 'error')
      }
    }
  }
}
</script>

<style>
.bounce-1 {
  animation-delay: 2.5s !important;
}

.bounce-1, .bounce-2 {
  animation: bouncing 5s ease infinite;
}

@keyframes bouncing {
			0% {
          transform: translate3d(0px, 0px, 0px);
      }

      50% {
         transform: translate3d(0px, 40px, 0px);
      }

      100% {
          transform: translate3d(0px, 0px, 0px);
      }
}

</style>