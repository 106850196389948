<template>
  <div class="w-screen h-screen grid place-content-center">
    <h1 class="text-4xl">under development :v</h1>
 </div>
</template>

<script>
export default {
  name: 'FeaturePage',
}
</script>