<template>
 <div class="mx-0 md:mx-2 py-5">
    <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
        <h1 class="text-lg font-semibold uppercase">Guest List</h1>
        <button
          class="p-2 rounded-md text-primary bg-white flex flex-row gap-2 items-center"
          @click="touchImportInput()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          <span>import</span>
        </button>
    </div>        
   
    <div class="w-full bg-secondary rounded-lg mt-5 p-5 relative overflow-auto">
      <table class="table-auto relative w-full">
      <thead class="sticky -top-4 bg-transparent h-16">
        <tr class="text-left">
          <th>Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody v-if="guests.data && guests.data.length > 0" >
        <tr v-for="(guest, index) in guests.data" :key="index">
          <td class="truncate">{{ guest.name }}</td>
          <td class="flex flex-row gap-1">
            <button
              class="rounded-sm p-1 bg-yellow-500 text-white"
              :class="
                guest.phone_number
                  ? 'bg-yellow-500'
                  : 'bg-yellow-200 cursor-not-allowed'
              "
              :disabled="!guest.phone_number"
              @click="shareWa(guest)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5" class="text-center">No Data</td>
        </tr>
      </tbody>
    </table>
    </div>

    <div class="sticky -bottom-3 bg-white flex">
      <pagination
        :data="guests"
        @nextPage="getWeddingGuestList"
        @prevPage="getWeddingGuestList"
      />
    </div>

    <input
      id="importFile"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      class="hidden"
      @input="importGuest"
    />
  </div>
</template>

<script>
import { getFormatWaNumber } from '~/common/helper/changer'
import pagination from '~/components/common/pagination.vue'
import { convertWaData } from '~/common/helper/mutator/waFormatter'

export default {
   name: 'GuestTable',
   components: { pagination },
   layout: 'UserNavigation',
   middleware: ['auth'],
  transition: "slide-bottom",
    async asyncData({ $axios, params }) {
      const data = await $axios.$get(`/api/v1/weddings/${params.wedding_code}/show`)
      return { wedding: data.data, wedding_code : params.wedding_code }
    },
   data() {
    return {
      guests: {},
    }
   },
   mounted(){
      this.getWeddingGuestList(1)
   },
   methods: {
        touchImportInput() {
            const input = document.getElementById('importFile')
            input?.click()
        },
        importGuest(data) {
            const self = this

            self.$swal.showLoading()
            const form = new FormData()
            form.append('file', data.target.files[0])
            form.append('wedding_code', this.wedding.wedding_code)
            this.$axios.$post(`/api/v1/import/guests`, form).then((res) => {
              if (res.success) {
                self.$swal.fire('Imported!', res.message, 'success')
                self.getWeddingGuestList(1)
              } else {
                self.$swal.fire('Failed!', res.message, 'error')
              }
            })
        },
        shareWa(guest) {
            const url =
            location.origin +
            '/' +
            this.wedding_code +
            '?to=' +
            guest.code

            const phone = getFormatWaNumber(guest.phone_number)

            const message = convertWaData(this.wedding.message, {...this.wedding, husbandNickname: this.wedding.husband_nickname, wifeNickname: this.wedding.wife_nickname, url, guest: guest.name})
            window.setTimeout(() => {
              window.open(
                  `https://api.whatsapp.com/send?phone=${phone}&text=${message}`
              )
            }, 500)
        },
        getWeddingGuestList(page = 1) {
            const self = this
            self.$swal.showLoading()
            
            const params = {
              page,
            }

           this.$axios.$get(`/api/v1/weddings/${this.wedding.id}/guests`, { params })
           .then(res => {
              if(res.success){
                this.guests = res.data
              }
              self.$swal.close()
           })
        },
   }
}
</script>

<style scoped>
tbody > tr {
  @apply hover:bg-primary/30 hover:text-white
}
td {
  @apply p-1 
}
</style>