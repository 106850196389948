<template>
   <div class="m-5">
        <hamburger :path="$route.path" />
        <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
            <h1 class="text-lg font-semibold uppercase">Create New Wedding</h1>
        </div>

        <div>
            <form-wedding />
        </div>
    </div>
</template>


<script>
import hamburger from '~/components/common/hamburger.vue'
import formWedding from '~/components/common/form/wedding.vue'

export default {
  name: 'CreateWeddingPage',
  components: {
    hamburger,
    formWedding
  },
  layout: 'UserNavigation',
  transition: "slide-bottom",
}
</script>