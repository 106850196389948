<template>
  <div class="m-5">
    <hamburger :path="$route.path" />
    <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
        <h1 class="text-lg font-semibold uppercase">Theme Management</h1>
         <nuxt-link
          :to="`/themes/create`" class="flex flex-row gap-2 items-center bg-white text-primary rounded-md px-2 py-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
            </svg>
            <span>Tambah</span>
        </nuxt-link>
    </div>

    <div class="w-full bg-secondary rounded-lg mt-5 p-5 relative">
      <table class="table-auto w-full">
        <thead>
          <tr class="text-left">
            <th>#</th>
            <th>Name</th>
            <th>Code</th>
            <th>Price</th>
            <th>Publish</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-if="themes && themes.length > 0">
          <tr v-for="(theme, index) in themes" :key="index">
            <td>{{ (index + 1) }}</td>
            <td class="uppercase">{{ theme.title }}</td>
            <td class="font-bold">{{ theme.code }}</td>
            <td class="font-bold"> {{ theme.currency === 'idr' ? 'Rp.' : '$' }} {{ theme.price.toLocaleString('id-ID') }}</td>
            <td class="italic">{{ theme.published_at ? theme.published_at : '-' }}</td>
            <td class="flex gap-1">
              <button
                v-if="!theme.published_at"
                class="rounded-sm p-1 bg-primary text-white"
                @click.prevent="onPublish(theme)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
              <button
                v-else
                class="rounded-sm p-1 bg-red-400 text-white"
                @click.prevent="onUnpublish(theme)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
              <nuxt-link
                :to="`/themes/${theme.id}`"
                class="rounded-sm p-1 bg-primary text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </nuxt-link>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">No Data</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import hamburger from '~/components/common/hamburger.vue'

export default {
  name: 'ThemePage',
  components: {
    hamburger,
  },
  layout: 'UserNavigation',
  middleware: ['admin'],
    transition: "slide-bottom",
  async asyncData({ $axios }) {
      const params = {
        page: 1,
      }
      
      const data = await $axios.$get(`/api/v1/themes`, { params })
      return { themes: data.data }
  },
  methods:{
      getThemes(page = 1) {
        this.$swal.fire('loading themes data')
        this.$swal.showLoading()

        const params = {
          page,
        }
        
        this.$axios.$get('/api/v1/themes', { params })
        .then((res) => {
          if (res.success) {
            this.themes = res.data
          }
        })
        .finally(() => this.$swal.close())
    },
    onPublish(payload){
        this.$swal.fire('publishing theme...')
        this.$swal.showLoading()
        this.$axios.$patch(`/api/v1/themes/${payload.id}/publish`)
            .then(() => {
               this.getThemes()
            })
            .finally(() => this.$swal.close())
    },
    onUnpublish(payload){
        this.$swal.fire('unpublishing theme...')
        this.$swal.showLoading()
        this.$axios.$patch(`/api/v1/themes/${payload.id}/unpublish`)
            .then(() => {
               this.getThemes()
            })
    }
  }
}
</script>