<template>
    <div>
      <hamburger :path="$route.path" />
      <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
          <h1 class="text-lg font-semibold uppercase">Song List</h1>
         <nuxt-link to="/songs/create">
            <button class="flex flex-row gap-2 items-center bg-white text-primary rounded-md px-2 py-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd" />
                </svg>
                <span>Tambah</span>
            </button>
         </nuxt-link>
      </div>
        <table class="table-auto w-full">
        <thead>
          <tr class="text-left">
            <th>#</th>
            <th>Song</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-if="songs && songs.data && songs.data.length > 0">
          <tr v-for="(song, index) in songs.data" :key="index">
            <td>{{ (index + 1) * songs.current_page }}</td>
            <td>{{ song.name }}</td>
            <td>
                <nuxt-link
                    :to="`/songs/${song.id}`"
                    >
                    <button 
                    class="px-1 py-1 rounded-md bg-primary text-white"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                    </button>
                </nuxt-link>
                <button
                    class="px-1 py-1 rounded-md bg-red-400 text-white"
                    @click="deleteSong(song.id)"
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="text-center">No Data</td>
          </tr>
        </tbody>
      </table>
       <div class="sticky -bottom-3 bg-white flex">
          <pagination
            :data="songs"
            @nextPage="getSongs"
            @prevPage="getSongs"
          />
      </div>
    </div>
</template>

<script>
import { isAdmin } from '~/common/helper/validator'
import hamburger from '~/components/common/hamburger.vue'
import pagination from '~/components/common/pagination.vue'

export default {
    name: "SongPage",
    components: { hamburger, pagination },
    layout: 'UserNavigation',
    middleware: ['admin'],
    transition: "slide-bottom",
     async asyncData({ $axios }) {
      const params = {
        page: 1,
      }
      
      const data = await $axios.$get(`/api/v1/songs`, { params })
      return { songs: data.data }
    },
    computed:{
      user(){
            return this.$auth.user
      },
      is_admin(){
          return isAdmin(this.user)
      }
    },
    methods:{
      getSongs(page = 1) {
          const self = this
          self.$swal.fire('fetching new data songs..')
          self.$swal.showLoading()
          
          const params = {
            per_page: 1,
            page,
          }

          this.$axios.$get(`/api/v1/songs`, { params })
          .then(res => {
            if(res.success){
              this.songs = res.data
            }
            self.$swal.close()
          })
      },
      deleteSong(id){
         const self = this

            self.$swal
            .fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete it!',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    self.$swal.fire('Deleting your data...')
                    self.$swal.showLoading()

                    self.$axios.$delete(
                        '/api/v1/songs/' + id,
                        self.song
                    ).then((res) => {
                        if (res.success) {
                            self.$swal.hideLoading()
                            self.$swal.fire('Deleted!', res.message, 'success')
                            self.getSongs()
                        }
                    })
                 }
            })
      }
    }
}
</script>