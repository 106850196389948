<template>
   <div class="m-5">
        <hamburger :path="$route.path" />
        <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
            <h1 class="text-lg font-semibold uppercase">Edit New User</h1>
        </div>

        <div class="w-full md:w-3/5">
            <form-user-edit :id="$route.params.id"/>
        </div>
    </div>
</template>


<script>
import hamburger from '~/components/common/hamburger.vue'
import formUserEdit from '~/components/common/form/user.vue'

export default {
  name: 'EditUserPage',
  components: {
    formUserEdit,
    hamburger
  },
  layout: 'UserNavigation',
    transition: "slide-bottom",
}
</script>