<template>
   <div class="m-5">
        <hamburger :path="$route.path" />
        <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
            <h1 class="text-lg font-semibold uppercase">Create New Wedding</h1>
        </div>
        <div class="flex flex-row my-2 bg-gray-100 justify-center">
            <button v-for="tab, i in tabs" :key="i" class="p-3 w-32 border-b-8 transition duration-100 ease-linear capitalize font-semibold" :class="tab.is_active ? 'border-primary font-bold' : 'border-gray-300'" @click="setActive(tab)">{{ tab.name }}</button>
        </div>
        <div v-if="tabs[0].is_active">
            <form-wedding :wedding-code="$route.params.wedding_code"/>
        </div>
        <div v-else-if="tabs[1].is_active">
            <form-timeline  :wedding-code="$route.params.wedding_code" />
        </div>
        <div v-else-if="tabs[2].is_active">
            <form-gallery  :wedding-code="$route.params.wedding_code" />
        </div>
        <div v-else-if="tabs[3].is_active">
            <form-gift  :wedding-code="$route.params.wedding_code" />
        </div>
        <div v-else-if="tabs[4].is_active">
            <form-event  :wedding-code="$route.params.wedding_code" />
        </div>
    </div>
</template>

<script>
import hamburger from '~/components/common/hamburger.vue'
import formWedding from '~/components/common/form/wedding.vue'
import formGift from '~/components/common/form/gift.vue'
import formGallery from '~/components/common/form/gallery.vue'
import formEvent from '~/components/common/form/event.vue'
import formTimeline from '~/components/common/form/timeline.vue'

export default {
   name: 'WeddingEditPage',
   components: { hamburger, formWedding, formGift, formGallery, formEvent, formTimeline },
   layout: 'UserNavigation',
   middleware: ['auth', 'admin'],
   transition: "slide-bottom",
   data() {
    return {
        tabs: [
            {
                name: 'detail',
                is_active: true,
            },
            {
                name: 'timeline',
                is_active: false,
            },
            {
                name: 'gallery',
                is_active: false,
            },
            {
                name: 'gift',
                is_active: false,
            },
            {
                name: 'events',
                is_active: false,
            }
        ]
    }
   },
    methods: {
        setActive(payload){
            this.tabs = this.tabs.map(tab => {
                return {
                    ...tab,
                    is_active : tab.name === payload.name
                }
            })
        }
    },
}
</script>
