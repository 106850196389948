<template>
  <div>
    <div id="cover" class="relative">
      <Navigation />

      <div class="h-screen w-screen flex justify-center items-center container-bg px-5">
        <div class="text-center Constantia">
          <h1 class="Constantia text-4xl lg:text-8xl mb-5 text-tertiary" data-aos="fade-up" data-aos-duration="500">
            {{ $t('weddingInvitation') }}
          </h1>
          <p class="text-lg lg:text-3xl Constantia lg:w-4/6 mx-auto" data-aos="fade-up" data-aos-duration="1000">
            {{ $t('makeSpecialDay') }}
          </p>
        </div>
      </div>

      <a id="floating-btn" href="#why" name="floating-btn"
        class="absolute bottom-5 right-5 md:right-10  rounded-full text-white animate-bounce border-2 border-white p-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
          stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
        </svg>
      </a>
    </div>

    <div id="why">
      <div class="lg:container lg:mx-auto py-24 px-10">
        <h1 class="text-tertiary text-2xl text-center lg:text-left">{{ $t('why') }} Bellioo ?</h1>
        <div class="flex flex-col lg:grid lg:grid-cols-5 gap-10">
          <div id="examples" class="flex flex-row col-span-2 items-center overflow-x-auto">
            <img src="~/assets/images/example-1.webp" class="w-auto h-auto" alt="bellioo wedding template 1">
            <img src="~/assets/images/example-2.webp" class="w-auto h-auto" alt="bellioo wedding template 2">
          </div>

          <div class="col-span-3 flex flex-col justify-center">
            <ol id="feature-list">
              <li class="mb-5" data-aos="fade-left" data-aos-duration="500">
                <h2 class="text-xl font-bold">{{ $t('feature.customizeDesign') }}</h2>
                <p class="text-base">{{ $t('feature.customizeDesignDescription') }}.</p>
              </li>
              <li class="mb-5" data-aos="fade-left" data-aos-duration="1000">
                <h2 class="text-xl font-bold">{{ $t('feature.professionalTeam') }}</h2>
                <p class="text-base">{{ $t('feature.professionalTeamDescription') }}.</p>
              </li>
              <li class="mb-5" data-aos="fade-left" data-aos-duration="1500">
                <h2 class="text-xl font-bold">{{ $t('feature.freeConsultation') }}</h2>
                <p class="text-base">{{ $t('feature.freeConsultationDescription') }}.</p>
              </li>
            </ol>

            <div class="text-right">
              <a id="contact-to" target="_blank" href="https://wa.me/+6285155288199"
                class="bg-tertiary px-3 py-2 rounded-md text-white text-lg">{{ $t('navigation.contact') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="features" class="py-16 md:py-32" style="background-color: #F6E5D9">
      <div class="md:mx-auto md:container">
        <h1 class="text-center text-5xl mb-5 font-bold">Features</h1>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-10 py-5 md:py-10">
          <div class="text-center p-5" data-aos="fade-left" data-aos-duration="500">
            <h2 class="text-xl font-bold mb-2">{{ $t('features.customizedDesign') }}</h2>
            <p>{{ $t('features.customizedDesignDescription') }}</p>
          </div>
          <div class="text-center p-5" data-aos="fade-left" data-aos-duration="600">
            <h2 class="text-xl font-bold mb-2">{{ $t('features.instagramFilter') }}</h2>
            <p>{{ $t('features.instagramFilterDescription') }}</p>
          </div>
          <div class="text-center p-5" data-aos="fade-left" data-aos-duration="800">
            <h2 class="text-xl font-bold mb-2">{{ $t('features.musicBackground') }}</h2>
            <p>{{ $t('features.musicBackgroundDescription') }}</p>
          </div>
          <div class="text-center p-5" data-aos="fade-left" data-aos-duration="1000">
            <h2 class="text-xl font-bold mb-2">{{ $t('features.countDownTime') }}</h2>
            <p>{{ $t('features.countDownTimeDescription') }}</p>
          </div>
          <div class="text-center p-5" data-aos="fade-left" data-aos-duration="1200">
            <h2 class="text-xl font-bold mb-2">{{ $t('features.rsvp') }}</h2>
            <p>{{ $t('features.rsvpDescription') }}</p>
          </div>
          <div class="text-center p-5" data-aos="fade-left" data-aos-duration="1400">
            <h2 class="text-xl font-bold mb-2">{{ $t('features.gift') }}</h2>
            <p>{{ $t('features.giftDescription') }}</p>
          </div>
          <div class="text-center p-5" data-aos="fade-left" data-aos-duration="1600">
            <h2 class="text-xl font-bold mb-2">{{ $t('features.googleMaps') }}</h2>
            <p>{{ $t('features.googleMapsDescription') }}</p>
          </div>
          <div class="text-center p-5" data-aos="fade-left" data-aos-duration="1800">
            <h2 class="text-xl font-bold mb-2">{{ $t('features.weddingWishes') }}</h2>
            <p>{{ $t('features.weddingWishesDescription') }}</p>
          </div>
          <div class="text-center p-5" data-aos="fade-left" data-aos-duration="2000">
            <h2 class="text-xl font-bold mb-2">{{ $t('features.photoGallery') }}</h2>
            <p>{{ $t('features.photoGalleryDescription') }}</p>
          </div>

        </div>
      </div>
    </div>

    <footer class="text-center py-2" style="background-color: #F6E5D9">
      <a href="https://bellioo.com"> Bellioo &#169; {{ (new Date).getFullYear() }}</a>
    </footer>
  </div>
</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
import Navigation from '~/components/Navigation.vue';

export default {
  name: "IndexPage",
  components: {
    Navigation
  },
  transition: "slide-bottom",
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      htmlAttrs: {
        myAttribute: this.$i18n.locale,
        ...i18nHead.htmlAttrs
      },
      title: this.$t("weddingInvitation"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("makeSpecialDay")
        },
        {
          hid: 'keywords',
          name: 'description',
          content: this.$t("meta.description")
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t("meta.title"),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t("meta.ogDescription")
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: 'Bellioo',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            'https://bellioo.com/logo.png',
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: `https://bellioo.com`,
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: `website`,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'twitter:site',
          name: 'twitter:site',
          content: '@andriarfazzan',
        },
        {
          hid: 'twitter:creator',
          name: 'twitter:creator',
          content: '@andriarfazzan',
        },
        {
          hid: 'twitter:url',
          name: 'twitter:url',
          content: `https://twitter.com/andriarfazzan`,
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: 'Bellioo - Wedding Invitation',
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.$t("meta.ogDescription")
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content:
            'https://bellioo.com/logo.png',
        },
        ...i18nHead.meta
      ],
      link: [
        ...i18nHead.link
      ]
    };
  },
  mounted() {
    AOS.init({ once: true });
  }
}
</script>

<style scoped>
.container-bg {
  background: #585858;
  background-image: url('~/assets/images/main-bg.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position-y: center;
}


@media only screen and (max-width: 780px) {
  .container-bg {
    background-position-y: center;
  }
}
</style>