<template>
 <div class="">
    <hamburger :path="$route.path" />
      <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
        <h1 class="text-lg font-semibold uppercase">Attendances & Wishes</h1>
      </div> 

      <div class="flex flex-wrap my-5 gap-3">
        <div
          class="flex flex-col md:flex-row text-center md:text-left md:items-end md:gap-2 w-full md:w-fit text-4xl font-bold rounded-md p-10 border border-solid border-gray-500 border-opacity-50"
        >
          <span>{{ summary.total_guest ? summary.total_guest : 0 }}</span>
          <span class="bottom-0 left-3 text-sm">Guest</span>
        </div>

        <div
          class="flex flex-col md:flex-row text-center md:text-left md:items-end md:gap-2 w-full md:w-fit text-4xl font-bold rounded-md p-10 border border-solid border-gray-500 border-opacity-50"
        >
          {{ summary.total_cannot_attend ? summary.total_cannot_attend : 0 }}
          <span class="bottom-0 left-3 text-sm">wouldn't attend</span>
        </div>
      </div>
      <div
        id="wishes"
        class="border border-gray-500 rounded-lg p-3 border-opacity-50 overflow-y-auto"
        style="max-height: 90vh"
      >
        <table class="table-auto w-full relative">
          <thead class="sticky -top-3 bg-white h-16">
            <tr class="text-left">
              <!-- <th>#</th> -->
              <th>Name</th>
              <th>Attend</th>
              <th>Guest</th>
              <th>Wish</th>
            </tr>
          </thead>
          <tbody v-if="wishes.data && wishes.data.length > 0" class="overflow-x-auto w-full">
            <tr v-for="(wish, index) in wishes.data" :key="index">
              <!-- <td>
                {{ wishes.per_page * (wishes.current_page - 1) + index + 1 }}
              </td> -->
              <td>{{ wish.name }}</td>
              <td>
                <button
                  v-show="wish.is_attended"
                  class="rounded-sm p-1 bg-primary text-white"
                  disabled
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
                <button
                  v-show="!wish.is_attended"
                  class="rounded-sm p-1 bg-red-500 text-white"
                  disabled
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </td>
              <td>{{ Math.round(wish.guest) }}</td>
              <td>{{ wish.description }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" class="text-center">No Data</td>
            </tr>
          </tbody>
        </table>

        <div class="sticky -bottom-3 bg-white flex">
          <pagination
            :data="wishes"
            @nextPage="getWishes"
            @prevPage="getWishes"
          />
        </div>
      </div>
  </div>
</template>

<script>
import pagination from '~/components/common/pagination.vue'
import hamburger from '~/components/common/hamburger.vue'

export default {
   name: 'WishTable',
   components: { pagination, hamburger },
   layout: 'UserNavigation',
   middleware: ['auth'],
    transition: "slide-bottom",
    async asyncData({ $axios, params }) {
      const data = await $axios.$get(`/api/v1/weddings/${params.wedding_code}/show`)
      return { wedding: data.data, wedding_code : params.wedding_code }
    },
   data() {
    return {
      wishes: {},
      summary: {},
    }
   },
   mounted(){
      this.getWishes(1)
      this.getSummary()
   },
   methods: {
    getWishes(page = 1){
        this.$swal.fire({ title: 'fetch data...' })
        this.$swal.showLoading()
        this.$axios.$get(
            `/api/v1/weddings/${this.wedding.id}/attendances`,
            { params: { page }}
        )
        .then((res) => {
            if (res.success) this.wishes = res.data
        })
        .finally(() => this.$swal.close())
    },

     getSummary() {
        this.$axios.$get(`/api/v1/weddings/${this.wedding.id}/attendances/summary`)
        .then((res) => {
            if (res.success) this.summary = res.data
        })
  }
   }
}
</script>

<style scoped>
tbody > tr {
  @apply hover:bg-primary/50 hover:text-white
}
td, th {
  @apply p-1 
}
</style>