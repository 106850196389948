<template>
    <div>
      <hamburger :path="$route.path" />
      <div class="bg-secondary p-3 mb-5 rounded-lg flex justify-between items-center">
          <h1 class="text-lg font-semibold uppercase">Song Create</h1>
      </div>
      <song-form />
    </div>
</template>

<script>
import songForm from '~/components/common/form/song.vue'
import hamburger from '~/components/common/hamburger.vue'

export default {
    name: "SongCreatePage",
    components: { hamburger, songForm },
    layout: 'UserNavigation',
    transition: "slide-bottom",
}
</script>